"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.easeInOutBounce = exports.easeInBounce = exports.easeOutBounce = exports.easeInOutBack = exports.easeOutBack = exports.easeInBack = exports.easeInOutElastic = exports.easeOutElastic = exports.easeInElastic = exports.easeInOutCirc = exports.easeOutCirc = exports.easeInCirc = exports.easeInOutExpo = exports.easeOutExpo = exports.easeInExpo = exports.easeInOutSine = exports.easeOutSine = exports.easeInSine = exports.easeInOutQuint = exports.easeOutQuint = exports.easeInQuint = exports.easeInOutQuart = exports.easeOutQuart = exports.easeInQuart = exports.easeInOutCubic = exports.easeOutCubic = exports.easeInCubic = exports.easeInOutQuad = exports.easeOutQuad = exports.easeInQuad = void 0;
exports.easeInQuad = function (elps, from, to, dr) { return (to * (elps /= dr) * elps + from); };
exports.easeOutQuad = function (elps, from, to, dr) { return (-to * (elps /= dr) * (elps - 2) + from); };
exports.easeInOutQuad = function (elps, from, to, dr) {
    if ((elps /= dr / 2) < 1) {
        return to / 2 * elps * elps + from;
    }
    return -to / 2 * (--elps * (elps - 2) - 1) + from;
};
exports.easeInCubic = function (elps, from, to, dr) { return (to * (elps /= dr) * elps * elps + from); };
exports.easeOutCubic = function (elps, from, to, dr) { return (to * ((elps = elps / dr - 1) * elps * elps + 1) + from); };
exports.easeInOutCubic = function (elps, from, to, dr) {
    if ((elps /= dr / 2) < 1) {
        return to / 2 * elps * elps * elps + from;
    }
    return to / 2 * ((elps -= 2) * elps * elps + 2) + from;
};
exports.easeInQuart = function (elps, from, to, dr) { return (to * (elps /= dr) * elps * elps * elps + from); };
exports.easeOutQuart = function (elps, from, to, dr) { return (-to * ((elps = elps / dr - 1) * elps * elps * elps - 1) + from); };
exports.easeInOutQuart = function (elps, from, to, dr) {
    if ((elps /= dr / 2) < 1) {
        return to / 2 * elps * elps * elps * elps + from;
    }
    return -to / 2 * ((elps -= 2) * elps * elps * elps - 2) + from;
};
exports.easeInQuint = function (elps, from, to, dr) { return (to * (elps /= dr) * elps * elps * elps * elps + from); };
exports.easeOutQuint = function (elps, from, to, dr) { return (to * ((elps = elps / dr - 1) * elps * elps * elps * elps + 1) + from); };
exports.easeInOutQuint = function (elps, from, to, dr) {
    if ((elps /= dr / 2) < 1) {
        return to / 2 * elps * elps * elps * elps * elps + from;
    }
    return to / 2 * ((elps -= 2) * elps * elps * elps * elps + 2) + from;
};
exports.easeInSine = function (elps, from, to, dr) { return (-to * Math.cos(elps / dr * (Math.PI / 2)) + to + from); };
exports.easeOutSine = function (elps, from, to, dr) { return (to * Math.sin(elps / dr * (Math.PI / 2)) + from); };
exports.easeInOutSine = function (elps, from, to, dr) { return (-to / 2 * (Math.cos(Math.PI * elps / dr) - 1) + from); };
exports.easeInExpo = function (elps, from, to, dr) { return (elps === 0 ? from : to * Math.pow(2, 10 * (elps / dr - 1)) + from); };
exports.easeOutExpo = function (elps, from, to, dr) { return elps === dr
    ? from + to
    : to * (-Math.pow(2, -10 * elps / dr) + 1) + from; };
exports.easeInOutExpo = function (elps, from, to, dr) {
    if (elps === 0) {
        return from;
    }
    if (elps === dr) {
        return from + to;
    }
    if ((elps /= dr / 2) < 1) {
        return to / 2 * Math.pow(2, 10 * (elps - 1)) + from;
    }
    return to / 2 * (-Math.pow(2, -10 * --elps) + 2) + from;
};
exports.easeInCirc = function (elps, from, to, dr) { return (-to * (Math.sqrt(1 - (elps /= dr) * elps) - 1) + from); };
exports.easeOutCirc = function (elps, from, to, dr) { return (to * Math.sqrt(1 - (elps = elps / dr - 1) * elps) + from); };
exports.easeInOutCirc = function (elps, from, to, dr) {
    if ((elps /= dr / 2) < 1) {
        return -to / 2 * (Math.sqrt(1 - elps * elps) - 1) + from;
    }
    return to / 2 * (Math.sqrt(1 - (elps -= 2) * elps) + 1) + from;
};
exports.easeInElastic = function (elps, from, to, dr) {
    var s = 1.70158;
    var p = 0;
    var a = to;
    if (elps === 0) {
        return from;
    }
    if ((elps /= dr) === 1) {
        return from + to;
    }
    if (!p) {
        p = dr * 0.3;
    }
    if (a < Math.abs(to)) {
        a = to;
        s = p / 4;
    }
    else {
        s = p / (2 * Math.PI) * Math.asin(to / a);
    }
    return (-(a * Math.pow(2, 10 * (elps -= 1)) * Math.sin((elps * dr - s) * (2 * Math.PI) / p))
        + from);
};
exports.easeOutElastic = function (elps, from, to, dr) {
    var s = 1.70158;
    var p = 0;
    var a = to;
    if (elps === 0) {
        return from;
    }
    if ((elps /= dr) === 1) {
        return from + to;
    }
    if (!p) {
        p = dr * 0.3;
    }
    if (a < Math.abs(to)) {
        a = to;
        s = p / 4;
    }
    else {
        s = p / (2 * Math.PI) * Math.asin(to / a);
    }
    return a * Math.pow(2, -10 * elps) * Math.sin((elps * dr - s) * (2 * Math.PI) / p) + to + from;
};
exports.easeInOutElastic = function (elps, from, to, dr) {
    var s = 1.70158;
    var p = 0;
    var a = to;
    if (elps === 0) {
        return from;
    }
    if ((elps /= dr / 2) === 2) {
        return from + to;
    }
    if (!p) {
        p = dr * (0.3 * 1.5);
    }
    if (a < Math.abs(to)) {
        a = to;
        s = p / 4;
    }
    else {
        s = p / (2 * Math.PI) * Math.asin(to / a);
    }
    if (elps < 1) {
        return (-0.5 * (a * Math.pow(2, 10 * (elps -= 1)) * Math.sin((elps * dr - s) * (2 * Math.PI) / p))
            + from);
    }
    return (a * Math.pow(2, -10 * (elps -= 1)) * Math.sin((elps * dr - s) * (2 * Math.PI) / p) * 0.5
        + to + from);
};
exports.easeInBack = function (elps, from, to, dr, s) {
    if (s === void 0) { s = 1.70158; }
    return (to * (elps /= dr) * elps * ((s + 1) * elps - s) + from);
};
exports.easeOutBack = function (elps, from, to, dr, s) {
    if (s === void 0) { s = 1.70158; }
    return (to * ((elps = elps / dr - 1) * elps * ((s + 1) * elps + s) + 1) + from);
};
exports.easeInOutBack = function (elps, from, to, dr, s) {
    if (s === void 0) { s = 1.70158; }
    if ((elps /= dr / 2) < 1) {
        return to / 2 * (elps * elps * (((s *= 1.525) + 1) * elps - s)) + from;
    }
    return to / 2 * ((elps -= 2) * elps * (((s *= 1.525) + 1) * elps + s) + 2) + from;
};
exports.easeOutBounce = function (elps, from, to, dr) {
    if ((elps /= dr) < 1 / 2.75) {
        return to * (7.5625 * elps * elps) + from;
    }
    if (elps < 2 / 2.75) {
        return to * (7.5625 * (elps -= 1.5 / 2.75) * elps + 0.75) + from;
    }
    if (elps < 2.5 / 2.75) {
        return to * (7.5625 * (elps -= 2.25 / 2.75) * elps + 0.9375) + from;
    }
    return to * (7.5625 * (elps -= 2.625 / 2.75) * elps + 0.984375) + from;
};
exports.easeInBounce = function (elps, from, to, dr) { return (to - exports.easeOutBounce(dr - elps, 0, to, dr) + from); };
exports.easeInOutBounce = function (elps, from, to, dr) {
    if (elps < dr / 2) {
        return exports.easeInBounce(elps * 2, 0, to, dr) * 0.5 + from;
    }
    return exports.easeOutBounce(elps * 2 - dr, 0, to, dr) * 0.5 + to * 0.5 + from;
};
